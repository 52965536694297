exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-101-jsx": () => import("./../../../src/pages/news/101.jsx" /* webpackChunkName: "component---src-pages-news-101-jsx" */),
  "component---src-pages-products-gas-oil-jsx": () => import("./../../../src/pages/products/gas-oil.jsx" /* webpackChunkName: "component---src-pages-products-gas-oil-jsx" */),
  "component---src-pages-products-hydrocarbon-jsx": () => import("./../../../src/pages/products/hydrocarbon.jsx" /* webpackChunkName: "component---src-pages-products-hydrocarbon-jsx" */),
  "component---src-pages-products-industrial-lubricants-jsx": () => import("./../../../src/pages/products/industrial-lubricants.jsx" /* webpackChunkName: "component---src-pages-products-industrial-lubricants-jsx" */),
  "component---src-pages-products-jet-fuel-jsx": () => import("./../../../src/pages/products/jet-fuel.jsx" /* webpackChunkName: "component---src-pages-products-jet-fuel-jsx" */),
  "component---src-pages-products-solvents-jsx": () => import("./../../../src/pages/products/solvents.jsx" /* webpackChunkName: "component---src-pages-products-solvents-jsx" */),
  "component---src-pages-products-thinner-jsx": () => import("./../../../src/pages/products/thinner.jsx" /* webpackChunkName: "component---src-pages-products-thinner-jsx" */)
}

